export const RENDER_TIMING = 'render_timing'
export const NAVIGATE_TIMING = 'navigate_timing'
export const XHR_TIMING = 'xhr_timing'
export const UX = 'ux'
export const INTERNAL_LINK = 'internal_link'
export const EXTERNAL_LINK = 'external_link'
export const XHR_REQUEST = 'xhr_request'
export const XHR_RESPONSE = 'xhr_response'
export const LANGUAGE = 'language'
export const LOADING = 'loading'
export const REDIRECT = 'redirect'
export const ALERT = 'alert'
