module.exports = [{
      plugin: require('/Users/tomasvrana/Sites/tomasvrana.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/tomasvrana/Sites/tomasvrana.com/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/config/typography.js"},
    },{
      plugin: require('/Users/tomasvrana/Sites/tomasvrana.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
