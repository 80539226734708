
export default {
  colors: {
    primary: '#fefefe',
    primaryHighlight: '#0f4f0c',
    secondary: 'rgba(83, 87, 135, 0.95)',
    interactive: '#222',
    interactiveHighlight: 'rgb(63, 124, 126)',
    text: '#000',
    secondaryText: '#afafaf',
    accent: 'rgba(255, 255, 255, 0.2)',
    textInverted: '#000000',
    navLine: '#f3f3f4',
    buttonColor: '#ffffff',
    secondaryButtonBackground: '#444444',
    secondaryButtonColor: '#ffffff',
    secondaryButtonBackgroundHighlight: '#555555',
    pageRule: '#36395d',
    infoBox: '#2c2b3b',
    infoBoxText: '#ffffff',
    alertBackground: '#ffffff',
    alertForeground: '#000000',
    byron: '#2cbb69',
    shelley: '#689bfd',
    goguen: '#832dc4',
    basho: '#e6aa00',
    voltaire: '#eb2256'
  },
  dimensions: {
    contentWidth: '124rem',
    contentGutterSize: '2rem',
    mobileBreakpoint: 800,
    screenSizes: {
      small: 576,
      medium: 768,
      large: 992,
      extraLarge: 1000
    }
  }
}
